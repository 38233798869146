import React, { useState } from 'react';
import { Table, Tooltip, Button } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { PaymentData } from '../../types/main';
import { ColumnsType } from 'antd/es/table';
import { NullableStatusResponse } from '../../helpers/statusUtils'; 



export interface AllocationDetailsTableProps {
  dataSource: PaymentData[];
  setDataSource: (data: PaymentData[]) => void;
  handleAllocate: (
    keys: string[], 
    dataSource: PaymentData[], 
    setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
    setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setAllocationProgress: React.Dispatch<React.SetStateAction<NullableStatusResponse>>
  ) => Promise<boolean>;
  onRecordSelect: (record: PaymentData) => void;
  selectedRecord: PaymentData | null;
  allocationStatus: string;
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>;  
	setAllocationProgress:  React.Dispatch<React.SetStateAction<NullableStatusResponse>>;  
	setAllocationStatus: React.Dispatch<React.SetStateAction<string>>;
	setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>;  

}

const AllocationDetailsTable: React.FC<AllocationDetailsTableProps> = ({ 
  dataSource, 
  setDataSource,
  handleAllocate,
  onRecordSelect,
  selectedRecord,
  allocationStatus,
  setErrorMessage,
  setIsErrorModalVisible,
  setAllocationProgress,
  setAllocationStatus,
  setIsProgressModalVisible
}) => {
  const [isAllocatingAll, setIsAllocatingAll] = useState(false);

  const handleAllocateAll = async () => {
    setIsAllocatingAll(true);
    const allKeys = dataSource
      .filter(record => record.linkedToViewership && record.linkedViewershipPool)
      .map(record => record.key);
    
  
      try {
        await handleAllocate(
          allKeys, 
          dataSource, 
          setAllocationStatus,
          setIsProgressModalVisible, 
          setErrorMessage,
          setIsErrorModalVisible,
          setAllocationProgress
        );
      } finally {
        setIsAllocatingAll(false);
      }
    };
    
    

const getButtonColor = (record: PaymentData, status: string) => {
  if (record.linkedToViewership && record.linkedViewershipPool) {
    if (status === 'initial') {
      return '#1890ff';  // Blue: Ready to allocate
    } else {
      switch (status) {
        case 'processing': return '#faad14';  // Yellow: Processing
        case 'success': return '#52c41a';  // Green: Success
        case 'failure': return '#f5222d';  // Red: Failure
        default: return '#1890ff';  // Blue: Ready (fallback)
      }
    }
  } else {
    return '#d9d9d9';  // Grey: Not ready
  }
};

  const getAllocationLevel = (strategy: PaymentData['allocationStrategy']): string => {
    if (!strategy) return 'Not Set';
    const { platform, partner, channel, territory } = strategy;
    const selectedFields = [
      platform && 'Platform',
      partner && 'Partner',
      channel && 'Channel',
      territory && 'Territory'
    ].filter(Boolean);
    return selectedFields.length > 0 ? selectedFields.join(' + ') : 'Custom';
  };
  const columns: ColumnsType<PaymentData> = [
    {
      title: '',
      key: 'selection',
      width: 50,
      render: (_: any, record: PaymentData) => (
        <div style={{ textAlign: 'center' }}>
          {record.key === selectedRecord?.key ? (
            <CheckCircleFilled style={{ color: '#1890ff', fontSize: '18px' }} />
          ) : (
            <div style={{
              width: '16px',
              height: '18px',
              borderRadius: '50%',
              backgroundColor: '#d9d9d9',
              display: 'inline-block'
            }} />
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div>
          Record Summary {' '}
          <Tooltip title="Composed of Register Name + Filename">
            <InfoCircleOutlined style={{ color: '#1890ff' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'registerName',
      key: 'summary',
      width: 200,
      render: (text: string, record: PaymentData) => {
        const summary = `${record.registerName}`;
        const detailsTooltip = (
          <div>
            <p><strong>Partner:</strong> {record.partner}</p>
            <p><strong>File:</strong> {record.filename}</p>
            <p><strong>Payment Date:</strong> {record.paymentDate}</p>
            <p><strong>Channel:</strong> {record.channel}</p>
            <p><strong>Territory:</strong> {record.territory}</p>
            <p><strong>Platform:</strong> {record.platform}</p>
            <p><strong>Domain:</strong> {record.domain}</p>
            <p><strong>Label:</strong> {record.label}</p>
            <p><strong>Amount:</strong> {record.amount}</p>
          </div>
        );

        return (
          <Tooltip title={detailsTooltip} placement="right">
            <div 
              style={{ 
                cursor: 'pointer',
                padding: '8px',
                background: '#f0f2f5',
                borderRadius: '4px',
                border: '1px solid #d9d9d9',
                fontWeight: 'bold',
                color: '#1890ff'
              }}
            >
              {summary}
            </div>
          </Tooltip>
        );
      },
    },    
    {
      title: 'Allocation Level',
      dataIndex: 'allocationStrategy',
      key: 'allocationLevel',
      width: 210,
      render: (strategy: PaymentData['allocationStrategy']) => (
        <div>{getAllocationLevel(strategy)}</div>
      ),
    },
    {
      title: 'Allocation Period',
      dataIndex: 'allocationPeriod',
      key: 'allocationPeriod',
      width: 150,
      render: (text: string) => <div>{text || 'Not Set'}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (_: any, record: PaymentData) => {
        const isReady = record.linkedToViewership && record.linkedViewershipPool;
        const isProcessing = allocationStatus === 'processing';
        
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <button
              onClick={() => {
                console.log('Record being allocated:', record);
                console.log('Key being sent:', record.key);
                handleAllocate(
                  [record.key],
                  dataSource,
                  setAllocationStatus,
                  setIsProgressModalVisible,
                  setErrorMessage,
                  setIsErrorModalVisible,
                  setAllocationProgress
                );
              }}              
              disabled={!isReady || isProcessing}
              style={{ 
                backgroundColor: getButtonColor(record, allocationStatus),
                border: 'none',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                cursor: (!isReady || isProcessing) ? 'not-allowed' : 'pointer'
              }}
            >
              {isProcessing ? 'Processing...' : 'Allocate'}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setDataSource(dataSource.map(item => 
                  item.key === record.key 
                    ? { ...item, toBeProcessed: false }
                    : item
                ));
                if (selectedRecord?.key === record.key) {
                  onRecordSelect(null as any);
                }
              }}
              style={{ 
                backgroundColor: '#f5222d',
                border: 'none',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Remove
            </button>
          </div>
        );
      },
    }
  ];

  return (
    <>
    <Table
      dataSource={dataSource
        .filter(record => record.toBeProcessed)
        .map(record => ({
          ...record,
          tableKey: `allocation-${record.key}`
        }))
      }      
      rowKey="tableKey"  
      columns={columns}
      pagination={false}
      scroll={{ x: 'max-content' }}
      onRow={(record) => ({
        onClick: () => onRecordSelect(record),
        style: {
          background: record.key === selectedRecord?.key ? '#e6f7ff' : 'transparent',
          cursor: 'pointer'
        }
      })}
    />
    <Button
        onClick={handleAllocateAll}
        disabled={isAllocatingAll || allocationStatus === 'processing'}
        style={{
          marginTop: '16px',
          backgroundColor: '#1890ff',
          color: 'white',
        }}
      >
        {isAllocatingAll ? 'Allocating All...' : 'Allocate All'}
      </Button>
    </>
  );
};

export default AllocationDetailsTable;