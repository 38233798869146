import { message, Upload } from "antd";
import { useEffect, useState } from "react";
import type { UploadProps, UploadFile } from "antd/es/upload/interface";
import { authAxios, getTokenFromSessionStorage, getEmailFromSessionStorage } from "../../../utils/session_utils";
import { v4 as uuidv4 } from "uuid";
import { Form } from "antd";
import Papa from "papaparse";


const useEPGInputsUploadHook = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
    const [redirectToBatchJobs, setRedirectToBatchJobs] = useState<boolean>(false);
    const [uploadUUID, setUploadUUID] = useState<string>('');
    const [channelName, setChannelName] = useState<string>('');
    const [form] = Form.useForm();
    
    useEffect(() => {
        setUploadUUID(uuidv4());
        setChannelName("Nosey");
        console.log("uploadUUID", uploadUUID);
        console.log("channelName", channelName);
    }, []);


    useEffect(() => {
        console.log("uploadUUID", uploadUUID);
        console.log("channelName", channelName);
        form.setFieldsValue({ uploadUUID, channelName });
    }, [uploadUUID, channelName]);


    const uploadProps: UploadProps = {
        accept: ".csv",
        name: 'file',
        multiple: true,
        action: `${process.env.REACT_APP_BACK_END_API}/epg-generator/upload`,
        fileList,
        beforeUpload: (file) => {
            console.log("file", file);
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = e.target?.result;
                if (csv) {
                    Papa.parse(csv.toString(), {
                        complete: (result) => {
                            const rows = result.data;
                            if (rows && rows.length > 0) {
                                const headers = rows[0] as string[];
                                const programIndex = headers.indexOf("Program");
                                if (programIndex === -1) {
                                    message.error("CSV file must contain a 'Program' column.");
                                    setFileList([]);
                                    return Upload.LIST_IGNORE;
                                }
                                const programValues = new Set();
                                for (let i = 1; i < rows.length; i++) {
                                    const columns = rows[i];
                                    programValues.add((columns as string[])[programIndex]);
                                }
                                if (programValues.size !== 1) {
                                    message.error("All rows in the 'Program' column must have the same value. Found:" + Array.from(programValues).join(","));
                                    setFileList([]);
                                    return Upload.LIST_IGNORE;
                                }
                                setFileList([...fileList, file]);
                                return true;
                            }
                        },
                        error: (error: any) => {
                            message.error("Error parsing CSV file: " + error.message);
                            setFileList([]);
                            return Upload.LIST_IGNORE;
                        }
                    });
                }
            };
            reader.readAsText(file);
            return false;
        },
        headers: {
            authorization: `Bearer ${getTokenFromSessionStorage()}`,
        },
        data: {
            uploadUUID,
            channelName,
        },
        showUploadList: {
            showDownloadIcon: true,
            showRemoveIcon: false
        },
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log(info.file.response);
                console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e: React.DragEvent<HTMLElement>) {
            console.log('Dropped files', e.dataTransfer.files);
            return true;
        },
    };

	const handleSubmit = async () => {
        setLoading(true);
        const toEmail = getEmailFromSessionStorage();
        
        if (toEmail?.length === 0 || !toEmail?.endsWith("mvmediasales.com")) {
			message.error("Please enter a valid mvmediasales email address");
		} else {
			setLoading(true);
            try {
				const config = {
					method: "POST",
					url: `${process.env.REACT_APP_BACK_END_API}/epg-generator/submit-job`,
					data: {
                        uploadUUID,
                        channelName,
                        toEmail,
                    },
					headers: { accept: "application/json", "content-type": "application/json" },
				};
				const response = await authAxios(config);
				if (!response.data.err) {
					message.success(response.data.message);
					setRedirectToBatchJobs(true);
				} else {
					message.error("Something went wrong");
				}
				setLoading(false);

				// console.log(response.data); // Handle the response from the Node.js app
			} catch (error) {
				setLoading(false);
				console.error(error);
			}
        }
    };

    return { loading, handleSubmit, uploadProps, uploadUUID, channelName, form, redirectToBatchJobs };
};

export default useEPGInputsUploadHook;
