import { useCallback } from 'react';
import { GroupedViewershipData } from '../../../utils/common.interface';

export const useViewershipPool = (records: any) => {
  const getGroupedData = useCallback((groupBy: string[]): GroupedViewershipData => {
    console.log('getGroupedData called with records:', records);
    if (!records || Object.keys(records).length === 0) return {};

    const result: GroupedViewershipData = {};
 
    Object.entries(records).forEach(([platform, platformData]: [string, any]) => {
      Object.entries(platformData).forEach(([partner, partnerData]: [string, any]) => {
        Object.entries(partnerData).forEach(([channel, channelData]: [string, any]) => {
          Object.entries(channelData).forEach(([territory, territoryData]: [string, any]) => {
            Object.entries(territoryData).forEach(([year, yearData]: [string, any]) => {
              Object.entries(yearData).forEach(([quarter, quarterData]: [string, any]) => {
                Object.entries(quarterData).forEach(([month, monthData]: [string, any]) => {
                  const key = groupBy.map(field => {
                    switch (field.toLowerCase()) {
                      case 'platform': return platform;
                      case 'partner': return partner;
                      case 'channel': return channel;
                      case 'territory': return territory;
                      default: return '';
                    }
                  }).join('|');

                  if (!result[key]) {
                    result[key] = {
                      key,
                      platform,
                      partner,
                      channel,
                      territory,
                      asset_series: monthData.asset_series || '',
                      content_provider: monthData.content_provider || '',
                      year: parseInt(year),
                      quarter,
                      month: parseInt(month),
                      total_hov: 0,
                      time_periods: {}
                    };
                  }
                  
                  const timePeriod = `${year}-${String(month).padStart(2, '0')}`;
                  result[key].time_periods[timePeriod] = (result[key].time_periods[timePeriod] || 0) + monthData.total_hov;
                  result[key].total_hov += monthData.total_hov;
                });
              });
            });
          });
        });
      });
    });

    return result;
  }, [records]);

  return { getGroupedData };
};