import React, { FC, useState } from 'react';
import { Form, Select, FormInstance, Modal } from 'antd';

const { Option } = Select;
const { Item } = Form;

interface FileUploadFilter {
    type: string; 
    domain: string; 
    platform: string; 
    territory?: string;
    month?: string;
    //year?: string;
  }

interface UploaderFilterModalProps {
    uploaderFilterModalOpen: boolean;
    setUploaderFilters: (formData: FileUploadFilter | any) => void;
    uploaderFilterModalCancelHandler: () => void;
}

const UploaderFilterModal: FC<UploaderFilterModalProps> = ({
    uploaderFilterModalOpen, 
    setUploaderFilters,
    uploaderFilterModalCancelHandler, 
}) => {
    const [selectedDomain, setSelectedDomain] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState('');
    
    const [form] = Form.useForm(); // Declare the form here

    const platformOptions: { [key: string]: string[] } = {
        'Distribution Partners': ['Wurl', 'Pluto'],
       // 'Owned and Operated': ['Powr', 'Vizio', 'Roku'],
    };

    const territoryOptions: { [key: string]: string[] } = {
        'Pluto': ['United States', 'United Kingdom', 'Canada', 'Latin America', 'Brazil', 'India', 'Mexico', 'Denmark', 'Norway', 'Sweden']
    };

    const handleDomainChange = (value: string) => {
        setSelectedDomain(value);
        form.setFieldsValue({ platform: undefined });
    };

	const handleLabelChange = (value: string) => {
		console.log(value);
	};

    const handlePlatformChange = (value: string) => {
        setSelectedPlatform(value);
        form.setFieldsValue({ territory: undefined, month: undefined }); // Reset territory and month when platform changes
    };

    const handleTerritoryChange = () => {
        form.setFieldsValue({ month: undefined }); // Reset month when territory changes
    };

    const handleCancel = () => {
        console.log('handle cancel')
        uploaderFilterModalCancelHandler();
    };

    const handleSetFilters = async () => {
        try {
          await form.validateFields(); // Validate all fields
          const formValues = form.getFieldsValue();
          setUploaderFilters(formValues);
        } catch (errorInfo) {
          console.error('Failed to set filters:', errorInfo);
        }
      };

    return (
        <Modal 
            title="Uploader Filter" // Add a title to the modal
            open={uploaderFilterModalOpen}  // Use 'visible' instead of 'open'
            okText="Set"
            onCancel={handleCancel}
            onOk={handleSetFilters}
        >
            <Form form={form} layout='vertical' initialValues={{ type: 'Revenue' }}>
                <Item label="Type" name="type">
                    <Select placeholder='Label' onChange={handleLabelChange}>
                        <Option value="Revenue">Revenue</Option>
                        <Option value="Viewership_Revenue">Viewership + Revenue</Option>                   
                        <Option value="Viewership" disabled>Viewership (Coming Soon...)</Option>
                        <Option value="Payment" disabled>Payment (Expense) (Coming Soon...)</Option>                   
                    </Select>
                </Item>
                <Item label="Domain" name="domain">
                    <Select placeholder='Select Domain' onChange={handleDomainChange}>
                        <Option value="Distribution Partners">Distribution Partners</Option>
                        <Option value="Owned and Operated" disabled>Owned and Operated (Coming Soon...)</Option>                   
                    </Select>
                </Item>

                <Item label="Platform" name="platform">
                    <Select placeholder='Select Platform' disabled={!selectedDomain} onChange={handlePlatformChange}>
                        {selectedDomain && platformOptions[selectedDomain as keyof typeof platformOptions].map(platform => (
                            <Option key={platform} value={platform}>{platform}</Option>
                        ))}
                    </Select>
                </Item>

                {/* Render territory dropdown only when Pluto is selected */}
                {selectedPlatform === 'Pluto' && (
                    <Item label="Territory" name="territory" rules={[{ required: true, message: 'Please select territory' }]}>
                        <Select placeholder='Select Territory' onChange={handleTerritoryChange}>
                            {territoryOptions[selectedPlatform].map(territory => (
                                <Option key={territory} value={territory}>{territory}</Option>
                            ))}
                        </Select>
                    </Item>
                )}

                {/* Render month dropdown only when Pluto is selected */}
                {selectedPlatform === 'Pluto' && (
                    <Item label="Month" name="month" rules={[{ required: true, message: 'Please select a month' }]}>
                        <Select placeholder='Select Month'>
                            <Option value="1">January</Option>
                            <Option value="2">February</Option>
                            <Option value="3">March</Option>
                            <Option value="4">April</Option>
                            <Option value="5">May</Option>
                            <Option value="6">June</Option>
                            <Option value="7">July</Option>
                            <Option value="8">August</Option>
                            <Option value="9">September</Option>
                            <Option value="10">October</Option>
                            <Option value="11">November</Option>
                            <Option value="12">December</Option>
                        </Select>
                    </Item>
                )}

                {/* Render year dropdown only when Pluto is selected */}
                {selectedPlatform === 'Pluto' && (
                    <Item label="Year" name="year" rules={[{ required: true, message: 'Please select a year' }]}>
                        <Select placeholder='Select Year'>
                            <Option value="2023">2023</Option>
                            <Option value="2024">2024</Option>
                            <Option value="2025">2025</Option>
                            <Option value="2026">2026</Option>
                            <Option value="2027">2027</Option>
                        </Select>
                    </Item>
                )}

            </Form>
        </Modal>
    );
}

export default UploaderFilterModal;
