import React, { useState, useEffect, useRef } from 'react';
import { Progress } from 'antd';
import { NullableStatusResponse } from '../helpers/statusUtils';
   
interface UploadProgressProps {
  uploadStatus: NullableStatusResponse;
  mode?: 'upload' | 'allocation';
}

const getColorForPercent = (percent: number) => {
  // Define our color stops in order of progress, every 10%
  const colorStops = [
    { percent: 0, color: '#108ee9' },     // Darkest blue
    { percent: 10, color: '#1777d6' },    // Darker blue
    { percent: 20, color: '#1890ff' },    // Blue
    { percent: 30, color: '#2c9aff' },    // Blue-medium
    { percent: 40, color: '#40a9ff' },    // Medium blue
    { percent: 50, color: '#54b1ff' },    // Light medium blue
    { percent: 60, color: '#69baff' },    // Light blue
    { percent: 70, color: '#69c0ff' },    // Lighter blue
    { percent: 80, color: '#6ac5ff' },    // Very light blue
    { percent: 90, color: '#36cfc9' },    // Blue-cyan
    { percent: 100, color: '#36cfc9' }    // Cyan
  ];

  // Find the two colors we should interpolate between
  for (let i = 0; i < colorStops.length - 1; i++) {
    if (percent >= colorStops[i].percent && percent <= colorStops[i + 1].percent) {
      const startColor = colorStops[i].color;
      const endColor = colorStops[i + 1].color;
      return { start: startColor, end: endColor };
    }
  }

  // Fallback
  return { start: '#108ee9', end: '#1890ff' };
};

const calculateBasePercent = (status?: string) => {
  const statusPercentMap: { [key: string]: number } = {
    'initiating': 5,
    'processing': 10,
    'checking_for_dupes': 13,
    'payment_dupes_checked': 18,  
    'uploaded': 28, 
    'normalized': 90,
    'viewership_fetched': 98,
    'completed': 99,
    // Allocation statuses
    'allocation_started': 2,
    'pre_processing': 4,
    'checking_rollup_table_for_dupes': 5,
    'performing_rollups': 30,
    'preparing_lambda_payloads': 40,
    'invoking_lambda': 60,
    'allocation_completed': 85
  };

  return statusPercentMap[status || ''] || 20;
};


const UploadProgress: React.FC<UploadProgressProps> = ({ 
  uploadStatus, 
  mode = 'upload' 
}) => {
  const [percent, setPercent] = useState(0);
  const lastStatusRef = useRef<string | undefined>(undefined);
  const maxPercentRef = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const lastModeRef = useRef<string>('upload');

  useEffect(() => {
    // Reset handling remains same
    if (mode !== lastModeRef.current || 
        uploadStatus?.status === 'initiating' || 
        uploadStatus?.status === 'allocation_started') {
      setPercent(0);
      maxPercentRef.current = 0;
      lastStatusRef.current = undefined;
      lastModeRef.current = mode;
    }
  
    if (!uploadStatus) return;
  
    // Get current status's base percent
    const currentBasePercent = calculateBasePercent(uploadStatus.status);
    
    // Set to current status's base percent immediately
    setPercent(currentBasePercent);
    
    // Create interval to crawl towards next target
    intervalRef.current = setInterval(() => {
      setPercent(prev => {
        if (prev >= currentBasePercent + 30) {  // +30 is room to crawl before next status
          if (intervalRef.current) clearInterval(intervalRef.current);
          return Math.min(prev, 100);
        }
    
        const distanceToTarget = (currentBasePercent + 30) - prev;
        const speedMultiplier = uploadStatus.status === 'uploaded' ? 0.02 : 0.1;
        const increment = Math.random() * (distanceToTarget * speedMultiplier); 
    
        return Math.min(prev + increment, 100);
      });
    }, uploadStatus.status === 'uploaded' ? 200 : 100);
  
    // Cleanup on unmount or status change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [uploadStatus?.status, mode]);



const progressColors = uploadStatus?.status === 'error' 
? { start: '#f5222d', end: '#cf1322' }
: getColorForPercent(percent);


  return (
    <Progress 
      percent={Math.round(percent)}
      status={uploadStatus?.status === 'error' ? 'exception' : 'active'}
      strokeColor={{
        '0%': progressColors.start,
        '100%': progressColors.end,
      }}
    />
  );
};

export default UploadProgress;