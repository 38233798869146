import React, { useState } from 'react';
import { Button, Card, Col, Row, Typography, Upload } from "antd";
import styled from "@emotion/styled";
import { UploadOutlined } from "@ant-design/icons";

import useImportDataHook from "./hooks/useImportDataHook";
import { TaskRow } from "../../zype/styled-components";
import { ComponentTitle, StledSpin } from "../../custom-components";

import UploaderFilterModal from './modals/UploaderFilterModal'; // Adjust the import path accordingly
import { FilterData } from '../../utils/types';

import './file_upload.css';

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
	padding: 10px;
	& .ant-upload-drag {
		padding: 10px;
	}
`;

const FileUploadComponent = () => {

  const [isFiltersSet, setIsFiltersSet] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true); // This ensures modal is open by default
  const [filterData, setFilterData] = useState<FilterData | null>(null);
  const [processingStatus, setProcessingStatus] = useState('');
  const { loading, handleUpload, uploadProps } = useImportDataHook(setProcessingStatus);


  const closeFilterModal = () => {
    setIsModalOpen(false);
  };

  const openFilterModal = () => {
    setIsModalOpen(true);
  };

  const handleIsFiltersSet = (value: boolean) => {
    setIsFiltersSet(value);
  };

  const handleSetFilters = (formSelections: FilterData) => {
    setFilterData({
      domain: formSelections.domain,
      platform: formSelections.platform,
      type: formSelections.type,
      territory: formSelections.territory,
      month: formSelections.month,
      year: formSelections.year
    });

    handleIsFiltersSet(true);
    setIsModalOpen(false); // Close modal after setting filters
  };

  const handleUploadWrapper = async () => {
    console.log('Filter Data:', filterData); // Log the value of filterData
    if (filterData &&
        filterData.type &&
        filterData.platform &&
        filterData.domain &&
        (filterData.platform === 'Wurl' || (filterData.month && filterData.year && filterData.territory))
    ){
      await handleUpload(filterData); // Proceed with upload since filterData is valid
    } else {
      console.error("Filter data is incomplete. Cannot proceed with upload.");
    }
  };



  return (
    <div>
      {isModalOpen  &&(
        <UploaderFilterModal
          uploaderFilterModalOpen={isModalOpen}
          setUploaderFilters={handleSetFilters}
          uploaderFilterModalCancelHandler={closeFilterModal}
        />
      )}

      {!isFiltersSet &&(
        <button onClick={openFilterModal} className="button-style">
          Set Filters
        </button>
      )}

      {isFiltersSet &&(
        <div>
          <div className="filters-container">
            <h2 className="filter-title">Selected Filters</h2>
            <div className="filter-item">
              <p><strong>Domain:</strong> {filterData?.domain}</p>
            </div>
            <div className="filter-item">
              <p><strong>Platform:</strong> {filterData?.platform}</p>
            </div>
            <div className="filter-item">
              <p><strong>Type:</strong> {filterData?.type}</p>
            </div>
            {filterData?.platform === 'Pluto' && (
              <>
                <div className="filter-item">
                  <p><strong>Territory:</strong> {filterData?.territory}</p>
                </div>
                <div className="filter-item">
                  <p><strong>Month:</strong> {filterData?.month}</p>
                </div>
                <div className="filter-item">
                  <p><strong>Year:</strong> {filterData?.year}</p>
                </div>
              </>
            )}
        </div>

          <div className="reset-button-container">
            <button
              onClick={() => { setIsFiltersSet(false); setIsModalOpen(true); }}
              className="button-style"
            >
              Reset Filters
            </button>
          </div>

          <StledSpin spinning={loading}>
            <Row justify="center">
              <Col>
                <ComponentTitle>
                  Import Data
                </ComponentTitle>
              </Col>
            </Row>
            <TaskRow>
              <Card
                actions={[
                  <Button key="import-btn" type="primary" onClick={handleUploadWrapper}>
                    Import Data
                  </Button>,
                ]}
              >
                <StyledDragger {...uploadProps}>
                  <Typography.Paragraph>
                    Please select a CSV file to import data into the database
                  </Typography.Paragraph>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </StyledDragger>
              </Card>
            </TaskRow>
          </StledSpin>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
