// All possible statuses including internal ones
export type UploadStatus = 
  | 'initiating'
  | 'processing' 
  | 'uploaded'
  | 'normalized'
  | 'viewership_fetched'
  | 'completed'
  | 'checking_for_dupes'
  | 'payment_dupes_checked'
  | 'allocation_started'
  | 'pre_processing'
  | 'checking_rollup_table_for_dupes'
  | 'performing_rollups'
  | 'preparing_lambda_payloads'
  | 'invoking_lambda'
  | 'allocation_completed'
  | 'error';

export interface StatusResponse {
  status: UploadStatus;
  message?: string;
  data?: any;
}

export const getStatusText = (
  response: NullableStatusResponse
 ) => {
  if (!response) return '';
 
  
  const statusMap: Record<UploadStatus, string> = {
    'initiating': 'Initiating upload...',
    'processing': 'Processing data...',
    'uploaded': 'Data uploaded, validating...',
    'normalized': 'Normalizing data...',
    'viewership_fetched': 'Upload completed! Switching to allocation mode...',
    'checking_for_dupes': 'Checking for duplicate payments...',
    'payment_dupes_checked': 'Duplicate check completed...',
    'completed': 'Upload completed!',
    'error': 'Error occurred',
    'allocation_started': 'Starting allocation process...',
    'pre_processing': 'Pre-processing allocation data...',
    'checking_rollup_table_for_dupes': 'Checking rollup table...',
    'performing_rollups': 'Performing rollups...',
    'preparing_lambda_payloads': 'Preparing allocation data...',
    'invoking_lambda': 'Job successfully launched! The job is now being processed. You will receive an email when it is complete. (2-3 minutes)',
    'allocation_completed': 'Job successfully launched! The job is now being processed. You will receive an email when it is complete. (2-3 minutes)',
  };
  
  const baseText = statusMap[response.status] || response.status;
  return response.message ? `${baseText} - ${response.message}` : baseText;
};
// Helper function to check if a status is terminal
export const isTerminalStatus = (status: UploadStatus | null): boolean => {
  if (!status) return false;
  return ['completed', 'error', 'viewership_fetched', 'allocation_completed'].includes(status);
};

// Helper function to check if a status is an error
export const isErrorStatus = (status: UploadStatus): boolean => {
  return status === 'error';
};

// Helper function to check if status should continue polling
export const shouldContinuePolling = (status: UploadStatus): boolean => {
  return !isTerminalStatus(status);
};


export type NullableStatusResponse = StatusResponse | null;
