import { UploadFile, RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { PaymentData } from '../types/main';  // Assuming you have this type defined

// Helper function to clean headers
export const cleanHeader = (header: string): string => {
  return header
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s_]/g, '')
    .replace(/\s+/g, '_');
};

// Helper function to format row data
export const formatRowData = (row: Record<string, unknown>, index: number): PaymentData => ({
  key: index.toString(),
  domain: row.domain?.toString() || '',
  platform: row.platform?.toString() || '',
  partner: row.partner?.toString() || '',
  channel: row.channel?.toString() || '',
  territory: row.territory?.toString() || '',
  amount: row.amount?.toString() || '',
  department: row.department?.toString() || '',
  title: row.title?.toString() || '',
  type: row.type?.toString() || '',
  description: row.description?.toString() || '',
  label: row.label?.toString() || '',
  registerName: row.register_name?.toString() || '',
  year: row.year?.toString() || '',
  quarter: row.quarter?.toString() || '',
  month: row.month?.toString() || '',
  impressions: row.impressions?.toString() || '',
  cpms: row.cpms?.toString() || '',
  paymentDate: normalizeDateFormat(row.payment_date?.toString() || '').normalizedDate,
  allocationStrategy: {
    platform: true,
    partner: true,
    channel: true,
    territory: true,
    allocationStrategy: {}
  },
  allocationLevel: row.allocation_level?.toString() || '',
  allocationPeriod: row.allocation_period?.toString() || '',
  linkedViewershipPool: '',
  linkedToViewership: false,
  selected: false,
  toBeProcessed: true,
  filename: ''
});


export const normalizeDateFormat = (dateInput: string): { 
  normalizedDate: string; 
  error?: string; 
} => {
  if (!dateInput) {
    return { normalizedDate: '', error: 'Date input is required' };
  }
  
  const cleanDate = dateInput.replace(/[^\w\s/-]/g, '');
  
  const formats = [
    // YYYY-MM-DD, YYYY/MM/DD, YYYYMMDD
    /^(\d{4})(?:[-/])?(\d{1,2})(?:[-/])?(\d{1,2})$/,
    // MM-DD-YYYY, MM/DD/YYYY, MM-DD-YY, MM/DD/YY
    /^(\d{1,2})[-/](\d{1,2})[-/](\d{2,4})$/,
    // DD-MM-YYYY, DD/MM/YYYY, DD-MM-YY, DD/MM/YY
    /^(\d{1,2})[-/](\d{1,2})[-/](\d{2,4})$/,
    // MMM DD YYYY, DD MMM YYYY
    /^(?:(\d{1,2})\s+)?([A-Za-z]{3})\s+(?:(\d{1,2})\s+)?(\d{4})$/
  ];

  for (const format of formats) {
    const match = cleanDate.match(format);
    if (match) {
      const [, ...parts] = match;
      let [first, second, third] = parts;
      const fourth = parts[3];
      
      // Handle month name format
      if (second && isNaN(parseInt(second))) {
        const monthNames: Record<string, string> = {
          'jan': '01', 'feb': '02', 'mar': '03', 'apr': '04', 
          'may': '05', 'jun': '06', 'jul': '07', 'aug': '08', 
          'sep': '09', 'oct': '10', 'nov': '11', 'dec': '12'
        };
        
        const monthKey = second.toLowerCase();
        const monthNum = monthNames[monthKey];
        if (!monthNum) {
          return { normalizedDate: '', error: `Invalid month name: ${second}` };
        }

        // Handle both MMM DD YYYY and DD MMM YYYY
        if (first) {
          const day = first;
          [first, second, third] = [fourth, monthNum, day];
        } else {
          const day = third;
          [first, second, third] = [fourth, monthNum, day];
        }
      }

      // Handle MM-DD-YY(YY) format
      if (match[0].match(/^\d{1,2}[-/]/)) {
        if (third.length === 2) {
          const year = parseInt(third);
          const prefix = year >= 50 ? '19' : '20';
          third = prefix + third.padStart(2, '0');
        }
        [first, second, third] = [third, first, second];
      }

      const year = parseInt(first);
      const month = parseInt(second);
      const day = parseInt(third);

      if (year < 1900 || year > 2100) {
        return { normalizedDate: '', error: `Year ${year} must be between 1900 and 2100` };
      }

      if (month < 1 || month > 12) {
        return { normalizedDate: '', error: `Month must be between 1 and 12` };
      }

      const daysInMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > daysInMonth) {
        return { normalizedDate: '', error: `Invalid day for ${month}/${year}` };
      }

      return { 
        normalizedDate: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` 
      };
    }
  }

  return { normalizedDate: '', error: 'Invalid date format' };
}

// Handle CSV file parsing
const handleCSVFile = async (
  fileObj: Blob
): Promise<PaymentData[]> => {  
  const text = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Failed to read file as text'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(fileObj);
  });

  return new Promise((resolve, reject) => {
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      transformHeader: cleanHeader,
      complete: (results) => {
        const formattedData = results.data.map((row: unknown, index) => 
          formatRowData(row as Record<string, unknown>, index)
        );

        resolve(formattedData);
      },
      error: (error: any) => {
        console.error('CSV parsing error:', error);
        reject(error);
      }
    });
  });
};
  
// Handle Excel file parsing
const handleExcelFile = async (fileObj: Blob): Promise<PaymentData[]> => {
  const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        resolve(result);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsArrayBuffer(fileObj);
  });

  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(firstSheet) as Record<string, unknown>[];

  const cleanedData = jsonData.map(row => {
    const cleanedRow: Record<string, unknown> = {};
    Object.entries(row as Record<string, unknown>).forEach(([key, value]) => {
      cleanedRow[cleanHeader(key)] = value;
    });
    return cleanedRow;
  });

  return cleanedData.map((row, index) => formatRowData(row, index));
};

// Import .csv or excel file
export const handleDataFileUpload = async (
  file: UploadFile | RcFile,
  setDataSource: (data: PaymentData[]) => void,
  setUploadedFilename: (filename: string) => void
): Promise<boolean> => {
  try {
    const fileObj = 'originFileObj' in file ? file.originFileObj : file;
    if (!fileObj) {
      message.error('Invalid file object');
      return false;
    }

    const fileExtension = file.name?.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      message.error('Invalid file extension');
      return false;
    }
    
    if (file.name) {
      setUploadedFilename(file.name);
    }

    let formattedData: PaymentData[];
    
    if (fileExtension === 'csv') {
      formattedData = await handleCSVFile(fileObj as Blob);
      message.success('CSV data loaded successfully');
    } else if (['xlsx', 'xls'].includes(fileExtension)) {
      formattedData = await handleExcelFile(fileObj as Blob);
      message.success('Excel data loaded successfully');
    } else {
      message.error('Unsupported file type');
      return false;
    }

    formattedData = formattedData.map(row => ({
      ...row,
      filename: file.name
    }));
    
    setDataSource(formattedData);
    return false;
  } catch (error) {
    console.error('File processing error:', error);
    message.error('Error processing file');
    return false;
  }
};