import { useState } from "react";
import { message, notification } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { authAxios } from "../../../utils/session_utils";
import { useLocation } from "react-router-dom";

const useImportDataHook = (updateProcessingStatus: (status: string) => void) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [file, setFile] = useState<RcFile | null>(null);

  const handleFileRemove = () => {
    setFile(null);
    setFileList([]);
    return true;
  };

  const beforeUpload = (file: RcFile) => {
    setFileList([file]);
    setFile(file);
    return false; // Prevent automatic upload
  };

  const uploadProps: UploadProps = {
    accept: ".csv",
    onRemove: handleFileRemove,
    beforeUpload: beforeUpload,
    fileList,
  };

  const handleUpload = async (filterData: any) => {
    if (!file) {
      message.error("Please insert a file first!");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      const userEmail = window.sessionStorage.getItem("mvmgsid") || ""; 
      formData.append("file", file as RcFile); // Correctly cast the file when appending
      formData.append("filterData", JSON.stringify(filterData));
      formData.append("userEmail", userEmail);
      
      const response = await authAxios({
        method: "POST",
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 202) { // Check for the 202 Accepted response
        message.info(response.data.message); // Inform the user that processing has started
        notification.success({
          message: "Processing started",
          description: `The file is being processed by Snowflake. You can track the progress in the Snowflake dashboard using this file. ${response.data.filename}`,
        })
  
        // Assuming response.data contains an operationId
        const { operationId } = response.data;
        updateProcessingStatus("processing")
        pollForStatus(operationId); // Start polling for the status of the processing
      } else {
        message.error("Something went wrong");
      }
  
    } catch (error) {
      message.error("An error occurred during upload.");
    } finally {
      setLoading(false);
    }
  };

  return { pathname, loading, file, handleUpload, uploadProps };
};


const pollForStatus = (operationId: string) => {  
  let elapsedTime = 0; // Variable to track elapsed time
  
  const checkStatus = async () => {
    try {
      const config = {
        method: 'get', 
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/operation-status/${operationId}`, 
      };
      
      const statusResponse = await authAxios(config);

      if (statusResponse.data.status === 'success') {
        message.success('File processed successfully');
      } else if (statusResponse.data.status === 'processing') {
        if (elapsedTime < 180000) { // Check if less than 3 minutes elapsed
          setTimeout(checkStatus, 5000); // Schedule another check after 5 seconds
          elapsedTime += 5000; // Increment elapsed time by 5 seconds
        } else {
          message.warning('Timeout: Processing taking too long'); // Display timeout message
        }
      } else {
        message.error('Processing failed');
      }
    } catch (error) {
      console.log("error: ", error)
      message.error('Failed to check processing status');
    }
  };

  checkStatus(); 
};

export default useImportDataHook;
